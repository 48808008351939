import React from "react";

const PrivacyPage = () => (
  <div>
    <h4>WAYBRIDGE TECHNOLOGIES INC.&nbsp;</h4>
    <h4>1. PURPOSE OF THIS PRIVACY NOTICE&nbsp;</h4>
    <p>
      Waybridge Technologies Inc. takes your privacy seriously. This notice
      (“Privacy Notice”) explains how we collect, use, disclose, retain and
      protect information that identifies you personally (your “Personal
      information”), and it applies to each of our websites, apps, services or
      tools that contain a link to this Privacy Notice (collectively, the
      “Services”). We reserve the right to update or change this Privacy Notice
      from time to time and if we do so, the updated version will be made
      available through the Services and/or otherwise made available to you, and
      we will provide a prominent notice and/or contact you by email or other
      method if we make material changes to it. By using the Services after
      receipt of any notice of an update to this Privacy Notice, you agree to
      the updated version to the extent permitted by law. Please periodically
      review this Privacy Notice for the latest information on our practices
      with respect to your Personal Information.&nbsp;
    </p>
    <h4>
      &nbsp;
      <br />
    </h4>
    <h4>2. INFORMATION WE COLLECT&nbsp;</h4>
    <p>
      When you use the Services, we may ask you to provide us with certain
      Personal Information that can be used to contact or identify you. We
      collect or receive information in different ways, depending on how you use
      and interact with the Services. In some instances, you can choose which
      information you provide to us, but in others certain information is
      required so that we can provide the Services to you. More information on
      this is immediately below. &nbsp;
    </p>
    <ul>
      <li>
        <strong>Personal identifiers.</strong> This includes&nbsp;information
        provided when you create or update your user account or fill out a web
        form, such as your name, business address, e-mail addresses, payment
        information (including your credit card details), phone numbers, login
        and password.&nbsp;
      </li>
      <li>
        <strong>Business information.</strong> This&nbsp;includes
        information&nbsp;about services or products you purchased, your feedback
        or reviews, or details related to your business.&nbsp;
        <br />
        Information generated from your use of the Services.&nbsp;This includes
        device, location and demographic
        information.&nbsp;Device&nbsp;information includes whether you are using
        our website or apps, your device and connection type and browser, and
        may include your device’s IP address (see our Cookie Policy below for
        more information on how this information is actually
        collected).&nbsp;&nbsp;Location&nbsp;information is collected to ensure
        that the Services are provided to the most accurate location (you can
        usually turn location sharing off in your device’s
        settings).&nbsp;Demographic&nbsp;information includes gender, interests
        and favorites, including through user surveys.&nbsp;
      </li>
      <li>
        <strong>Your content.</strong> We may collect from information you post
        on our website or on social media, or when you contact or otherwise
        communicate with us. It enables us to tailor the Services to your
        preferences. If your content contains any information about another
        person, please make sure you have their consent to share it.&nbsp;
      </li>
      <li>
        <strong>Information collected from third parties.</strong> This
        information serves to supplement the information we collect directly
        from you. It can be from commercial data aggregators or marketing and
        advertising partners.&nbsp;
      </li>
      <li>
        <strong>For referral services.</strong>&nbsp;We may offer for you to
        tell friends about the Services. In order to use this referral service,
        we will need to collect your contact’s name and email address, and they
        would receive an automatic one-time invitation via email that we send on
        your behalf inviting them to use our Services.&nbsp;
      </li>
    </ul>
    <p>
      &nbsp;
      <br />
    </p>
    <h4>3. HOW WE USE YOUR PERSONAL INFORMATION&nbsp;</h4>
    <p>
      We use your Personal Information to provide, improve and personalize the
      Services for you, if we need to contact you about your user account or the
      Services, to provide you with customer support, or to prevent fraud or
      other illegal activities. Additional details on how we use your Personal
      Information are below.&nbsp;
    </p>
    <ul>
      <li>
        <strong>To provide you with the Services.</strong> This includes
        processing and communicating with you about your use of the Services and
        to provide you with customer support or otherwise contact you about your
        use of the Services.&nbsp;
      </li>
      <li>
        <strong>To communicate with you.</strong> This includes to answer any
        questions you may have of us, or with respect to any of your account
        activity.&nbsp;
      </li>
      <li>
        <strong>To improve and personalize the Services for you.</strong> This
        helps us provide you with a more customized user experience based on
        your provided preferences. We also might get information about you from
        third parties and combine it with what you’ve provided to us for this
        purpose.&nbsp;
      </li>
      <li>
        <strong>For security purposes.&nbsp;</strong>This is to help protect
        you, our Services, company and our other customers from fraud or other
        illegal activities.&nbsp;
      </li>
      <li>
        <strong>For marketing purposes.</strong>&nbsp;This is so we can let you
        know about new features or promotional offers that you might be
        interested in based on your provided preferences.&nbsp;
      </li>
      <li>
        <strong>To pursue our legitimate interest.&nbsp;</strong>For example,
        direct marketing, research (including marketing research), network and
        information security and fraud prevention.&nbsp;
      </li>
      <li>
        We use Personal Information as otherwise permitted or required by law,
        as long as it’s necessary or relevant to our business, or as we may
        otherwise notify you.&nbsp;
        <br />
      </li>
    </ul>
    <p>
      &nbsp;
      <br />
    </p>
    <h4>4. SHARING OF YOUR PERSONAL INFORMATION&nbsp;</h4>
    <p>
      We may share your Personal Information with third parties under certain
      limited circumstances. Additional detail on who we share your information
      with, and why we share it, is below.&nbsp;
    </p>
    <ul>
      <li>
        <strong>Third party service&nbsp;providers.&nbsp;</strong>They&nbsp;may,
        as our trusted service providers, need certain of your Personal
        Information for payment processing, billing, account security, customer
        support, personalized advertising and other business operations. Third
        party service providers and/or the equipment on which such service
        providers store or process Personal Information may be located outside
        the United States.&nbsp;
      </li>
      <li>
        <strong>Third party collaborations.</strong>&nbsp;After removing certain
        identifiers, like your name, phone, and e-mail address (where provided),
        and combining the resulting information with similar information from
        other users, we may use, sell, license and/or share your information
        with third parties for research, business or other purposes.&nbsp;
      </li>
      <li>
        <strong>
          As part of a business transaction or in connection with negotiations
          and preparations for a possible business transaction.
        </strong>{" "}
        This includes if we are involved in a merger, acquisition, financing due
        diligence or other similar event.&nbsp;
      </li>
      <li>
        <strong>Credit agencies or bureaus.</strong> We may need to share (where
        legally permitted) certain information if you’ve missed payments or
        defaulted on your user account.&nbsp;
      </li>
      <li>
        <strong>Our affiliated or related entities.</strong> This is in order to
        ensure that you have a secure and consistent experience using the
        Services. These companies may be providing you with various products and
        services as our business evolves.&nbsp;
      </li>
      <li>
        To comply with applicable law, enforce our terms of service, prevent,
        detect, mitigate or investigate fraud or other illegal activity or to
        protect our legitimate business interests.&nbsp;
      </li>
    </ul>
    <p>
      &nbsp;
      <br />
    </p>
    <h4>5. YOUR CHOICES</h4>
    <p>
      You have a choice about how we handle your Personal Information. There are
      various ways you are able to control the personal information of yours
      that we receive and retain, and how your activity is tracked when using
      the Services.&nbsp;
    </p>
    <ul>
      <li>
        <strong>In general.&nbsp;</strong>You have the right to object to or opt
        out of certain uses of your Personal Information. Where you have
        consented to the processing of your Personal Information, you may
        withdraw that consent at any time by contacting us at{" "}
        <a
          data-mce-href="mailto:support@waybridge.com"
          href="mailto:support@waybridge.com"
        >
          support@waybridge.com
        </a>
        .&nbsp;
      </li>
      <li>
        <strong>To opt out of marketing communications.&nbsp;</strong>You can
        opt out of marketing communications by following the instructions in any
        marketing email. This does not apply to transactional messages that
        relate specifically to your use of the Services.&nbsp;
      </li>
      <li>
        <strong>Updating your account information.</strong> This includes
        updating or deleting incorrect user account information (you must log in
        to your account to do this).&nbsp;&nbsp;
      </li>
      <li>
        <strong>Cookies, tracking tools and your mobile device.</strong> Your
        mobile device should allow you to control the types of notifications you
        receive and to turn off GPS location services if you prefer not to share
        that information. When you use the Services, we may place a “cookie” or
        other online tracking device (such as a web beacons or pixels) in order
        to improve your experience by recognizing you when you visit the
        Services, such as by assigning a session ID and to deliver content
        specific to your interests. The cookies we place may also collect
        information about your IP address or clickstream data within the
        Services (i.e., the actions taken in connection with the Services). This
        information helps us improve the functionality of the Services. We also
        use cookies provided by Google Analytics and ZoomInfo to help us measure
        how visitors use the Services and to obtain additional information about
        the type of visitors accessing the Services. The information collected
        is used for a variety of purposes, such as site traffic reporting and
        analysis, marketing purposes, unique visitor counts and content
        optimization. Although Google Analytics and ZoomInfo monitor and log the
        information coming from the Services on our behalf, we control how the
        data may and may not be used. If you do not want to be tracked in this
        manner to help us learn how to improve the Services, you can opt-out of
        these website analysis tools by clicking:&nbsp;&nbsp;
        <a
          data-mce-href="https://tools.google.com/dlpage/gaoptout"
          href="https://tools.google.com/dlpage/gaoptout"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://tools.google.com/dlpage/gaoptout
        </a>
        &nbsp; or &nbsp;
        <a
          data-mce-href="https://www.zoominfo.com/privacy-center/manage-profile"
          href="https://www.zoominfo.com/privacy-center/manage-profile"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.zoominfo.com/privacy-center/manage-profile
        </a>
        . Web browsers often allow you to erase existing cookies from your hard
        drive, block the use of cookies and/or be notified when cookies are
        encountered. If you elect to block cookies, please note that you may not
        be able to take full advantage of the features and functions of the
        Services. &nbsp;&nbsp;
      </li>
      <li>
        <strong>Do Not Track (“DNT”).&nbsp;</strong>This is a privacy preference
        that users can set in certain web browsers. We do not respond to or
        honor DNT signals or similar mechanisms transmitted by web
        browsers.&nbsp;
      </li>
      <li>
        <strong>Shine the Light Law (for California residents).</strong> If you
        are a California resident and have an established business relationship
        with us, you have the right to request certain information regarding our
        disclosure of your personal information to third parties for direct
        marketing purposes. To make such a request, you can email{" "}
        <a
          data-mce-href="mailto:support@waybridge.com"
          href="mailto:support@waybridge.com"
        >
          support@waybridge.com
        </a>
        .&nbsp;&nbsp;
      </li>
    </ul>
    <h4>
      &nbsp;
      <br />
    </h4>
    <h4>6. HOW LONG DO WE KEEP YOUR INFORMATION FOR?&nbsp;</h4>
    <p>
      We keep your Personal Information for as long as is reasonably necessary
      in order to provide you with the Services, or until your account has been
      deleted (whichever happens first). This is subject to any longer period
      that might be required by applicable law or notified to you. If you delete
      your account, it may take additional time to fully delete your Personal
      Information. We may retain Personal Information from deleted accounts in
      order to prevent fraud, enforce our rights under our terms of service, to
      enforce our legal rights generally or to comply with our legal
      obligations.&nbsp;
    </p>
    <h4>
      &nbsp;
      <br />
    </h4>
    <h4>7. OUR SECURITY MEASURES&nbsp;</h4>
    <p>
      We strive to protect your Personal Information using reasonable, industry
      standard technical and administrative security measures to reduce the
      risks of loss, misuse, unauthorized access, disclosure and alteration.
      However, no one can guarantee absolute protection on the Internet, so we
      cannot ensure or warrant the security of any information you provide to
      us.&nbsp;
    </p>
    <h4>
      &nbsp;
      <br />
    </h4>
    <h4>8. INTERNATIONAL DATA TRANSFERS&nbsp;</h4>
    <p>
      You understand and agree that we may transfer and process your Personal
      Information anywhere in the world, including the United States. We are
      located in and subject to United States laws, which may not afford the
      same level of protection as those in your country.&nbsp;We will take all
      reasonably necessary steps to ensure that your Personal Information is
      treated securely and in accordance with this Privacy Notice.&nbsp; By
      providing us with any Personal Information, you consent to our transfer of
      Personal Information across national borders.&nbsp;
    </p>
    <p>
      &nbsp;
      <br />
    </p>
    <h4>9. THE SERVICES ARE NOT INTENDED FOR CHILDREN&nbsp;</h4>
    <p>
      Our Services are meant for adults. We do not knowingly collect Personal
      Information from people under the age of 16, nor do we sell such Personal
      Information. If you are a parent or guardian and you are aware that your
      child has provided us with Personal Information, please contact us at{" "}
      <a
        data-mce-href="mailto:support@waybridge.com"
        href="mailto:support@waybridge.com"
      >
        support@waybridge.com
      </a>
      . If we become aware that we have collected Personal Information from
      children under 16 without verification of parental or guardian consent, we
      take steps to remove that information from our servers.&nbsp;
    </p>
    <p>
      &nbsp;
      <br />
    </p>
    <h4>
      10. WE MAY LINK TO THIRD PARTY SITES OR SERVICES WE DON&apos;T
      CONTROL&nbsp;
    </h4>
    <p>
      The Services may contain links to third party websites that we do not
      control. This Privacy Notice does not apply to the privacy practices of
      those websites. Read the privacy notices of other websites carefully. We
      are not responsible for these&nbsp;third party&nbsp;sites. The Services
      may also serve third party content that contains their own cookies or
      tracking technologies, and we do not control the use of those
      technologies.&nbsp;
    </p>
    <p>
      &nbsp;
      <br />
    </p>
    <h4>11. QUESTIONS OR CONCERNS?&nbsp;</h4>
    <p>
      If you need to reach us about anything related to this Privacy Notice or
      our handling of your Personal Information, please contact us via email at{" "}
      <a
        data-mce-href="mailto:support@waybridge.com"
        href="mailto:support@waybridge.com"
      >
        support@waybridge.com
      </a>
      , or by writing to Waybridge Technologies Inc., 150 W 22nd Street, 11th
      Floor, New York, NY 10011, Attn: Privacy. Please reference this Privacy
      Notice and do not include any sensitive details.&nbsp;&nbsp;
      <br />
    </p>
  </div>
);

export default PrivacyPage;
